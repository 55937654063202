import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
//import VueCompositionAPI from '@vue/composition-api'
import vuetify from "./plugins/vuetify";
import i18n from './i18n'
import VueMatomo from 'vue-matomo'

//Vue.use(VueCompositionAPI)
//Vue.use(Vuetify)

Vue.use(VueMatomo, {
  host: 'https://stats.celp.de',
  siteId: 1,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Enables automatically registering pageviews on the router
  router: router,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: true,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: true,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Set this to include crossorigin attribute on the matomo script import
  // Default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,

});



Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
